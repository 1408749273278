.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.wrapper .container{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-40);
  padding: var(--spacing-24) 0;
  cursor: pointer;
  color: var(--text-color);
  transition: opacity 0.3s ease-in-out;
}

.wrapper span:hover{
  opacity: 0.8;
}

.wrapper span:active{
  opacity: 0.6;
}

.picto{
  flex: 0 0 auto;
  color: var(--text-color);
}

.title{
  font: var(--font-h3-mobile-default);
  font-family: var(--archivo-font);
  font-weight: 900;
  color: var(--text-color);
}

.content > div{
  display: flex;
  color: var(--text-color);
  font: var(--font-p1-desktop-default);
  padding-bottom: var(--spacing-24);
}

.active {
  color: var(--b-green);
}

@media (max-width: 768px){
  .title{
    font: var(--font-p1-mobile-default);
    font-weight: 900;
  }
  .content > div{
    font: var(--font-p1-mobile-default);
  }
}